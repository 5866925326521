import React from 'react'

export const logo = (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256.44 44.82"
  >
    <linearGradient id="lg" x1="0.5" y1="1" x2="0.5" y2="0">
      <stop offset="0%" stopOpacity="1" stopColor="royalblue" />
      <stop offset="40%" stopOpacity="1" stopColor="royalblue" />
      <stop offset="40%" stopOpacity="0" stopColor="royalblue" />
      <stop offset="100%" stopOpacity="0" stopColor="royalblue" />
    </linearGradient>
    <path
      d="M238.86,321.7v7H252.6v8.94H238.86v7.68H254.4v9.42H227V312.28H254.4v9.42Z"
      transform="translate(-227.04 -310.36)"
    />
    <path
      d="M279.84,344.68v10.08H275q-6.18,0-9.66-3.06t-3.48-10.14V330.94h-4.32V321.1h4.32v-8.16h11.82v8.16h6.06v9.84h-6.06V341.8a3,3,0,0,0,.69,2.22,3.29,3.29,0,0,0,2.31.66Z"
      transform="translate(-227.04 -310.36)"
    />
    <path
      d="M303.87,322.21a9.43,9.43,0,0,1,3.87,4.23V321.1H319.5v33.66H307.74v-5.34a9.43,9.43,0,0,1-3.87,4.23,11.94,11.94,0,0,1-6.15,1.53,13.2,13.2,0,0,1-7.26-2.07,14,14,0,0,1-5.1-6,23.66,23.66,0,0,1,0-18.39,14,14,0,0,1,5.1-6,13.2,13.2,0,0,1,7.26-2.07A11.94,11.94,0,0,1,303.87,322.21Zm-6.72,10.62a7.14,7.14,0,0,0-1.71,5.07,7.23,7.23,0,0,0,1.71,5.13,5.83,5.83,0,0,0,4.47,1.83A5.74,5.74,0,0,0,306,343a7.17,7.17,0,0,0,1.74-5.1,7.08,7.08,0,0,0-1.74-5,5.74,5.74,0,0,0-4.38-1.86A5.83,5.83,0,0,0,297.15,332.83Z"
      transform="translate(-227.04 -310.36)"
    />
    <path
      d="M355,322.75a14,14,0,0,1,5.1,6,23.66,23.66,0,0,1,0,18.39,14,14,0,0,1-5.1,6,13.2,13.2,0,0,1-7.26,2.07,11.78,11.78,0,0,1-6.12-1.53,9.47,9.47,0,0,1-3.84-4.23v5.34H326v-44.4H337.8v16.08a9.47,9.47,0,0,1,3.84-4.23,11.78,11.78,0,0,1,6.12-1.53A13.2,13.2,0,0,1,355,322.75Zm-15.54,10.11a7.08,7.08,0,0,0-1.74,5,7.17,7.17,0,0,0,1.74,5.1,5.74,5.74,0,0,0,4.38,1.86,5.83,5.83,0,0,0,4.47-1.83A7.23,7.23,0,0,0,350,337.9a7.14,7.14,0,0,0-1.71-5.07,5.83,5.83,0,0,0-4.47-1.83A5.74,5.74,0,0,0,339.48,332.86Z"
      transform="translate(-227.04 -310.36)"
    />
    <path
      d="M378.54,310.36v44.4H366.72v-44.4Z"
      transform="translate(-227.04 -310.36)"
    />
    <path
      d="M417.12,340.06H394.86q.3,5.7,5,5.7a4.73,4.73,0,0,0,2.88-.84,4.2,4.2,0,0,0,1.56-2.22h12.48a14.27,14.27,0,0,1-2.94,6.39,15.78,15.78,0,0,1-5.76,4.47,17.83,17.83,0,0,1-7.62,1.62,18.6,18.6,0,0,1-8.91-2.07,14.65,14.65,0,0,1-6-6,20.65,20.65,0,0,1,0-18.39,14.65,14.65,0,0,1,6.06-6,20.21,20.21,0,0,1,17.76,0,14.55,14.55,0,0,1,6,5.82,17.54,17.54,0,0,1,2.13,8.76A14.15,14.15,0,0,1,417.12,340.06ZM404,331.24a5.13,5.13,0,0,0-3.6-1.32,5.49,5.49,0,0,0-3.81,1.32,5.73,5.73,0,0,0-1.71,3.9h10.5A4.86,4.86,0,0,0,404,331.24Z"
      transform="translate(-227.04 -310.36)"
    />
    <path
      d="M439,322.6a12.16,12.16,0,0,1,6.36-1.8v12.6H441.9q-4,0-5.94,1.53t-2,5.31v14.52H422.16V321.1H434v6.3A15.41,15.41,0,0,1,439,322.6Z"
      transform="translate(-227.04 -310.36)"
    />
    <path
      d="M467.85,322.21a9.43,9.43,0,0,1,3.87,4.23V321.1h11.76v33.66H471.72v-5.34a9.43,9.43,0,0,1-3.87,4.23,11.94,11.94,0,0,1-6.15,1.53,13.2,13.2,0,0,1-7.26-2.07,14,14,0,0,1-5.1-6,23.66,23.66,0,0,1,0-18.39,14,14,0,0,1,5.1-6,13.2,13.2,0,0,1,7.26-2.07A11.94,11.94,0,0,1,467.85,322.21Zm-6.72,10.62a7.14,7.14,0,0,0-1.71,5.07,7.23,7.23,0,0,0,1.71,5.13,5.83,5.83,0,0,0,4.47,1.83A5.74,5.74,0,0,0,470,343a7.17,7.17,0,0,0,1.74-5.1,7.08,7.08,0,0,0-1.74-5A5.74,5.74,0,0,0,465.6,331,5.83,5.83,0,0,0,461.13,332.83Z"
      transform="translate(-227.04 -310.36)"
    />
  </svg>
)
