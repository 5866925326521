// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-case-tsx": () => import("../src/templates/case.tsx" /* webpackChunkName: "component---src-templates-case-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cases-tsx": () => import("../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-feed-tsx": () => import("../src/pages/feed.tsx" /* webpackChunkName: "component---src-pages-feed-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

